import React from 'react';
import AccordionFilterItem from 'components/AccordionFilterItem';

import styles from './accordion-filter.module.scss';

const AccordionFilter = (props) => {
  return (
    <div className={`${styles['aside-section-accordion']}`}>
      {props.topics.map((topicItem) => {
        return (
          <AccordionFilterItem
            key={topicItem._id}
            id={topicItem._id}
            topic={topicItem.topicName}
            handleSubtopicSelection={props.handleSubtopicSelection}
          />
        );
      })}
    </div>
  );
};

export default AccordionFilter;
