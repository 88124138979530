export const constructPrintableUrl = (apiResponse = {}) => {
  let printableUri = '';

  const responseKeys = Object.keys(apiResponse) || [];

  if (responseKeys.length !== 0 && responseKeys.length === 4) {
    printableUri = `${process.env.REACT_APP_PRINTABLE_CDN_URL}/${
      apiResponse[process.env.REACT_APP_PF_KEY]
    }/${apiResponse[process.env.REACT_APP_FF_KEY]}/${
      apiResponse[process.env.REACT_APP_FI_KEY]
    }.${apiResponse[process.env.REACT_APP_FE_KEY]}`;
  }

  return printableUri;
};
