import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from 'common/constants';

import axiosInstance from 'services/Network';
import PageLoader from 'components/PageLoader';
import ApplicationContext from 'hocs/ApplicationContext';

const LoadingContext = (props) => {
  const navigate = useNavigate();
  const { applicationContext, setApplicationContext } =
    useContext(ApplicationContext);

  useEffect(() => {
    if (applicationContext.authenticationToken) {
      axiosInstance.defaults.headers.common['Authorization'] =
        applicationContext.authenticationToken;
    } else if (sessionStorage.getItem('authToken') !== null) {
      setApplicationContext((currentContextState) => ({
        ...currentContextState,
        authenticationToken: sessionStorage.getItem('authToken'),
      }));
    } else {
      console.log('Pathname Was', window.location.pathname);

      if (window.location.pathname !== NavigationPaths.HOME) {
        // Unauthorized Access Scenario - Restrict Entry
        navigate(NavigationPaths.ROADBLOCK);
      }
    }
  }, [applicationContext]); // eslint-disable-line

  const toggleShowInstructions = () => {
    setApplicationContext((currentContextState) => ({
      ...currentContextState,
      instructionDialog: !currentContextState.instructionDialog,
    }));
  };

  return (
    <>
      {props.children}

      {applicationContext?.pageLoading && <PageLoader />}

      {applicationContext?.instructionDialog && (
        <div className='aside-help-dialog'>
          <button
            type='button'
            className='aside-help-close'
            onClick={() => toggleShowInstructions()}
          >
            <i className='fa-solid fa-xmark'></i>
          </button>

          <p className='aside-help-instructions'>
            Looking to filter by Topics / Sub-Topics? Choose a Class and Subject
            to enable those filtering options.
          </p>
        </div>
      )}
    </>
  );
};

export default LoadingContext;
