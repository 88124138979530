import { contextInitialState } from 'common/constants';
import { createContext } from 'react';

const defaultContextState = {
  ...contextInitialState,
  // Include Setter Function in the Default Initialization of the Context
  setApplicationContext: () => {},
};

const ApplicationContext = createContext(defaultContextState);

export default ApplicationContext;
