import React, { useContext, useEffect, useState } from 'react';

import isEqual from 'lodash/isEqual';
import Header from 'components/Header';
import cloneDeep from 'lodash/cloneDeep';
import FilterAside from 'components/FilterAside';
import GridElement from 'components/GridElement';
import MasterSearch from 'components/MasterSearch';
import WorksheetService from 'services/Worksheet';
import ApplicationContext from 'hocs/ApplicationContext';

import styles from './grid-landing.module.scss';

const GridLanding = () => {
  const { setApplicationContext } = useContext(ApplicationContext);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [prevParams, setPrevParams] = useState({});
  const [worksheets, setWorksheets] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedSubtopics, setSelectedSubtopics] = useState([]);
  const [selectedFilterClass, setSelectedFilterClass] = useState(
    process.env.REACT_APP_PRESELECT_CLASS
  );

  useEffect(() => {
    getWorksheets();
  }, [page, selectedFilterClass, selectedSubjects, selectedSubtopics]); // eslint-disable-line

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      getWorksheets();
    }
  }, [search]); // eslint-disable-line

  useEffect(() => {
    setPage(1);
  }, [selectedFilterClass, selectedSubjects, selectedSubtopics]); // eslint-disable-line

  const getWorksheets = () => {
    const params = {
      page,
      search,
      classId: selectedFilterClass,
      subjectIds: selectedSubjects,
      subtopicIds: selectedSubtopics,
    };

    if (isEqual(prevParams, params)) {
      return;
    } else {
      setPrevParams(cloneDeep(params));
    }

    setApplicationContext((currentContextState) => ({
      ...currentContextState,
      pageLoading: true,
    }));

    WorksheetService.worksheets(params)
      .then((response) => {
        setApplicationContext((currentContextState) => ({
          ...currentContextState,
          pageLoading: false,
        }));

        if (response && response.data) {
          setWorksheets(response.data);
        }
      })
      .catch((error) => {
        setApplicationContext((currentContextState) => ({
          ...currentContextState,
          pageLoading: false,
        }));

        console.log('Error while fetching Worksheets', error);
      });
  };

  return (
    <>
      <Header />

      <div className={`single-page ${styles['page-home']}`}>
        <FilterAside
          selectedFilterClass={selectedFilterClass}
          setSelectedSubjects={setSelectedSubjects}
          setSelectedSubtopics={setSelectedSubtopics}
          setSelectedFilterClass={setSelectedFilterClass}
        />

        <main
          className={`single-page-container ${styles['page-home-container']}`}
        >
          <section className={`${styles['page-home-intro']}`}>
            <p className={`${styles['page-home-text']}`}>
              Worksheet Warehouse has stocked up ready-to-print, highly engaging
              worksheets for using in class or at home. Each worksheet is tagged
              to skill, learning outcome and difficulty level. Search with
              keywords or topic to find just the right worksheet you may need.
            </p>

            <p className={`${styles['page-home-text']}`}>
              Use of Worksheet Warehouse is permitted only under{' '}
              <a
                href={process.env.REACT_APP_TEACHERS_LICENSE_URL || '#'}
                target='_blank'
                rel='noreferrer'
              >
                Teachers/Parents Licence
              </a>{' '}
              or{' '}
              <a
                href={process.env.REACT_APP_SCHOOL_LICENSE_URL || '#'}
                target='_blank'
                rel='noreferrer'
              >
                School Licence
              </a>
              .
            </p>
          </section>
          <MasterSearch search={search} setSearch={setSearch} />

          {worksheets && worksheets.length !== 0 ? (
            <section className={`${styles['page-home-grid']}`}>
              {worksheets.map((worksheetItem, worksheetIndex) => {
                return (
                  <GridElement
                    key={worksheetIndex}
                    slug={worksheetItem.slug}
                    class={worksheetItem?.class}
                    subject={worksheetItem?.subject}
                    title={worksheetItem.worksheetTitle}
                    classes={worksheetItem?.classes || []}
                    topic={worksheetItem?.topic?.topicName}
                    thumbnail={worksheetItem.screenshotUrl}
                    subtopics={worksheetItem?.subtopics || []}
                    subtopic={worksheetItem?.subtopic?.subtopicName}
                  />
                );
              })}
            </section>
          ) : (
            <section className={`${styles['page-home-empty']}`}>
              <p className={`${styles['page-home-text']}`}>
                Sorry! No worksheets are available for the selected search and
                filter criteria.
              </p>
            </section>
          )}

          <section className={`${styles['page-home-pagination']}`}>
            <button
              type='button'
              disabled={page < 2}
              onClick={() => setPage(page - 1)}
              className={`${styles['pagination-action']}`}
            >
              <i className='fa-solid fa-arrow-left'></i>
              {`Prev`}
            </button>
            <span className={`${styles['pagination-text']}`}>{page}</span>
            <button
              type='button'
              onClick={() => setPage(page + 1)}
              className={`${styles['pagination-action']}`}
              disabled={worksheets.length === 0 || worksheets.length < 20}
            >
              {`Next`}
              <i className='fa-solid fa-arrow-right'></i>
            </button>
          </section>
        </main>
      </div>
    </>
  );
};

export default GridLanding;
