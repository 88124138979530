import React, { useState } from 'react';
import { NavigationPaths } from 'common/constants';
import { contextInitialState } from 'common/constants';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import GridLanding from 'containers/GridLanding';
import LoadingContext from 'hocs/LoadingContext';
import WorksheetDetail from 'containers/WorksheetDetail';
import ApplicationContext from 'hocs/ApplicationContext';
import SSOAuthenticate from 'containers/SSOAuthenticate';
import AccessRoadBlock from 'containers/AccessRoadBlock';

import 'styles/imports.scss';

const Navigation = () => {
  const [applicationContext, setApplicationContext] =
    useState(contextInitialState);

  const contextValue = { applicationContext, setApplicationContext };

  return (
    <ApplicationContext.Provider value={contextValue}>
      <div className='application-wrapper'>
        <BrowserRouter>
          <LoadingContext>
            <Routes>
              <Route
                exact
                path={NavigationPaths.HOME}
                element={<SSOAuthenticate />}
              />
              <Route
                exact
                path={NavigationPaths.BROWSE}
                element={<GridLanding />}
              />
              <Route
                exact
                element={<WorksheetDetail />}
                path={NavigationPaths.DETAILS}
              />
              <Route
                exact
                element={<AccessRoadBlock />}
                path={NavigationPaths.ROADBLOCK}
              />

              {/* Catch-All Block */}
              <Route
                path={NavigationPaths.CATCHALL}
                element={<Navigate replace to={NavigationPaths.ROADBLOCK} />}
              />
            </Routes>
          </LoadingContext>
        </BrowserRouter>
      </div>
    </ApplicationContext.Provider>
  );
};

export default Navigation;
