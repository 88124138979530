import React, { useContext, useEffect } from 'react';
import { contextInitialState } from 'common/constants';

import Header from 'components/Header';
import ApplicationContext from 'hocs/ApplicationContext';

import PageLoader from 'assets/images/page-loader.gif';

import styles from './access-roadblock.module.scss';

const AccessRoadBlock = () => {
  const { setApplicationContext } = useContext(ApplicationContext);

  useEffect(() => {
    setApplicationContext({
      ...contextInitialState,
    });

    if (
      sessionStorage.getItem('authTime') !== null &&
      sessionStorage.getItem('authToken') !== null
    ) {
      sessionStorage.removeItem('authTime');
      sessionStorage.removeItem('authToken');
    }
  }, []); // eslint-disable-line

  return (
    <>
      <Header />

      <div className='single-page'>
        <main
          className={`single-page-container ${styles['page-logout-container']}`}
        >
          <section className={`${styles['page-logout-loading']}`}>
            <img
              src={PageLoader}
              alt='Loading...'
              className={`${styles['page-logout-loader']}`}
            />

            <h4 className={`${styles['page-logout-heading']}`}>
              You've been logged off!
            </h4>

            <p className={`${styles['page-logout-description']}`}>
              You're seeing this because either you have decided to log out, or
              something else related to authentication has went wrong.
            </p>

            <p className={`${styles['page-logout-description']}`}>
              To access Worksheet Warehouse again, please click on the{' '}
              <b>Worksheet Library</b> link on Neeve Teacher's Portal.
            </p>
          </section>
        </main>
      </div>
    </>
  );
};

export default AccessRoadBlock;
