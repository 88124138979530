import React from 'react';

import styles from './multi-check.module.scss';

const MultiCheck = (props) => {
  return (
    <label htmlFor={props.id} className={`${styles['multicheck-label']}`}>
      <input
        id={props.id}
        type='checkbox'
        value={props.value}
        onChange={props.onChange}
        className={`${styles['multicheck-label-input']}`}
      />

      <span className={`${styles['multicheck-label-selector']}`}>
        <i className='fa-solid fa-check'></i>
      </span>

      <span className={`${styles['multicheck-label-text']}`}>
        {props.label}
      </span>
    </label>
  );
};

export default MultiCheck;
