import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from 'common/constants';

import QueryString from 'query-string';
import Header from 'components/Header';
import AuthService from 'services/Auth';
import axiosInstance from 'services/Network';
import ApplicationContext from 'hocs/ApplicationContext';

import PageLoader from 'assets/images/page-loader.gif';

import styles from './sso-authenticate.module.scss';

const SSOAuthenticate = () => {
  const navigate = useNavigate();
  const { applicationContext, setApplicationContext } =
    useContext(ApplicationContext);

  const [authData, setAuthData] = useState({
    token: null,
    udiseCode: null,
  });
  const [pageTimer, setPageTimer] = useState(0);
  const [tryAgainEnabled, setTryAgainEnabled] = useState(false);

  useEffect(() => {
    const params = QueryString.parse(window.location.search);

    if (params && params.udise_code && params.auth_token) {
      setAuthData({
        token: params.auth_token,
        udiseCode: params.udise_code,
      });
    } else {
      navigate(NavigationPaths.ROADBLOCK);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    let timerCounter = undefined;

    if (pageTimer > 0) {
      timerCounter = setTimeout(() => {
        setPageTimer((currentPageTimer) => currentPageTimer - 1);
      }, 1000);
    } else {
      if (authData && authData.udiseCode && authData.token) {
        setTryAgainEnabled(true);
      }
    }

    return () => {
      if (timerCounter) {
        clearTimeout(timerCounter);
      }
    };
  }, [pageTimer]); // eslint-disable-line

  useEffect(() => {
    if (authData && authData.udiseCode && authData.token) {
      handleLogin();
    }
  }, [authData]); // eslint-disable-line

  useEffect(() => {
    if (applicationContext.authenticationToken !== null && pageTimer === 0) {
      navigate(NavigationPaths.BROWSE);
    }
  }, [applicationContext, pageTimer]); // eslint-disable-line

  const handleLogin = () => {
    setPageTimer(5);

    AuthService.login(authData)
      .then((response) => {
        if (response && response.data && response.data.accessToken) {
          axiosInstance.defaults.headers.common['Authorization'] =
            response.data.accessToken;

          setApplicationContext((currentContextState) => ({
            ...currentContextState,
            authenticationToken: response.data.accessToken,
          }));

          // Initialize Session with the Recieved Credentials
          sessionStorage.setItem('authTime', new Date());
          sessionStorage.setItem('authToken', response.data.accessToken);
        }
      })
      .catch((error) => {
        console.log('Error while trying to Login', error);
      });
  };

  const handleTryAgain = () => {
    if (tryAgainEnabled) {
      handleLogin();
      setTryAgainEnabled(false);
    }
  };

  return (
    <>
      <Header />

      <div className='single-page'>
        <main
          className={`single-page-container ${styles['page-home-container']}`}
        >
          <section className={`${styles['page-home-loading']}`}>
            <img
              src={PageLoader}
              alt='Loading...'
              className={`${styles['page-home-loader']}`}
            />

            <h4 className={`${styles['page-home-heading']}`}>
              Please wait while we get things ready.
            </h4>

            <p className={`${styles['page-home-description']}`}>
              {`Please wait as we verify your details for access to Worksheet Warehouse. This page should automatically refresh within `}
              <strong>{`${pageTimer}`}</strong>
              {` seconds.`}
            </p>

            <p
              className={`${styles['page-home-description']} ${styles['page-home-refresh']}`}
            >
              Page didn't refresh?
            </p>

            <button
              type='button'
              disabled={!tryAgainEnabled}
              onClick={() => handleTryAgain()}
              className={`${styles['page-home-retry']}`}
            >
              Try Again
            </button>
          </section>
        </main>
      </div>
    </>
  );
};

export default SSOAuthenticate;
