import axiosInstance from './Network';

import { APIPaths } from 'common/constants';

const WorksheetService = {
  worksheets: (dataPayload) => {
    return axiosInstance.post(APIPaths.WORKSHEETS, dataPayload);
  },

  filters: () => {
    return axiosInstance.get(APIPaths.FILTERS);
  },

  filtersTopics: (dataPayload) => {
    return axiosInstance.post(APIPaths.FILTERS_TOPICS, dataPayload);
  },

  filtersSubtopics: (dataPayload) => {
    return axiosInstance.post(APIPaths.FILTERS_SUBTOPICS, dataPayload);
  },

  worksheetDetails: (dataPayload) => {
    return axiosInstance.get(
      APIPaths.WORKSHEET_SLUG.replace(
        ':slugIdentifier',
        dataPayload.slugIdentifier
      )
    );
  },

  print: (dataPayload) => {
    return axiosInstance.get(
      APIPaths.WORKSHEET_PRINT.replace(':identifier', dataPayload.identifier)
    );
  },
};

export default WorksheetService;
