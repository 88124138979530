import axios from 'axios';
import get from 'lodash/get';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT_URL,
  timeout: 60000,
  headers: {
    'Access-Control-Allow-Headers':
      'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Authorization',
  },
  withCredentials: false,
});

axiosInstance.interceptors.request.use(
  (requestData) => {
    if (sessionStorage.getItem('authToken') !== null) {
      requestData.headers.common['Authorization'] =
        sessionStorage.getItem('authToken');
    }

    return requestData;
  },
  (requestError) => {
    console.log('+++++++++', 'Interceptor Request Starts', '+++++++++');
    console.log(requestError);
    console.log('+++++++++', 'Interceptor Request Ends', '+++++++++');

    return Promise.reject(requestError);
  }
);

axiosInstance.interceptors.response.use(
  (responseData) => {
    console.log('+++++++++', 'Interceptor Response Starts', '+++++++++');
    console.log(responseData.data);
    console.log('+++++++++', 'Interceptor Response Ends', '+++++++++');

    return responseData.data;
  },
  (errorData) => {
    const errorResponse = get(errorData, 'response.data');

    if (!errorResponse) {
      return Promise.reject({
        message:
          'ERROR: Network Connection Failure. Please Check Your Internet Access and Try Again.',
      });
    }

    console.log('+++++++++', 'Interceptor Response Starts', '+++++++++');
    console.log(errorResponse);
    console.log('+++++++++', 'Interceptor Response Ends', '+++++++++');

    return Promise.reject(errorResponse);
  }
);

export default axiosInstance;
