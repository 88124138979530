import React from 'react';

import AnimatedLoader from 'assets/images/page-loader.gif';

import styles from './page-loader.module.scss';

const PageLoader = () => {
  return (
    <div className={`${styles['page-loader']}`}>
      <img
        alt='Loading...'
        src={AnimatedLoader}
        className={`${styles['page-loader-image']}`}
      />

      <h4 className={`${styles['page-loader-title']}`}>
        Loading! Please Wait...
      </h4>
    </div>
  );
};

export default PageLoader;
