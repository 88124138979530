import React, { useContext, useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import MultiCheck from 'components/MultiCheck';
import SingleCheck from 'components/SingleCheck';
import WorksheetService from 'services/Worksheet';
import AccordionFilter from 'components/AccordionFilter';
import ApplicationContext from 'hocs/ApplicationContext';

import styles from './filter-aside.module.scss';

const FilterAside = (props) => {
  const { selectedFilterClass, setSelectedFilterClass } = props;

  const { setApplicationContext } = useContext(ApplicationContext);

  const [topics, setTopics] = useState([]);
  const [topicIds, setTopicIds] = useState([]);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedSubtopics, setSelectedSubtopics] = useState([]);
  const [selectedSubtopicsArr, setSelectedSubtopicsArr] = useState([]);

  useEffect(() => {
    getFilters();
  }, []); // eslint-disable-line

  const getFilters = () => {
    WorksheetService.filters()
      .then((response) => {
        if (response && response.data) {
          setSubjects(response.data.subjects);

          setClasses(response.data.classes);
        }
      })
      .catch((error) => {
        console.log('Error while fetching Filters', error);
      });
  };

  useEffect(() => {
    if (selectedFilterClass === '' || selectedSubjects.length === 0) {
      setTopics([]);
      setTopicIds([]);
    } else {
      getTopics();
    }
  }, [selectedFilterClass, selectedSubjects]); // eslint-disable-line

  useEffect(() => {
    const selectedSubtopicsArrCopy = cloneDeep(selectedSubtopicsArr);

    const validSelectedSubtopicsArr = selectedSubtopicsArrCopy.filter(
      (selectedSubtopicItem) => topicIds.includes(selectedSubtopicItem.topicId)
    );

    setSelectedSubtopicsArr(validSelectedSubtopicsArr);
    const validSelectedSubtopicsCopy = cloneDeep(
      validSelectedSubtopicsArr.map(
        (selectedSubtopicItem) => selectedSubtopicItem._id
      )
    );
    setSelectedSubtopics(validSelectedSubtopicsCopy);
    props.setSelectedSubtopics(validSelectedSubtopicsCopy);
  }, [topicIds]); // eslint-disable-line

  const getTopics = () => {
    if (selectedFilterClass === '' || selectedSubjects.length === 0) {
      return;
    }

    setApplicationContext((currentContextState) => ({
      ...currentContextState,
      pageLoading: true,
    }));

    WorksheetService.filtersTopics({
      classId: selectedFilterClass,
      subjectIds: selectedSubjects,
    })
      .then((response) => {
        setApplicationContext((currentContextState) => ({
          ...currentContextState,
          pageLoading: false,
        }));

        if (response && response.data) {
          setTopics(response.data);
          setTopicIds(response.data?.map((t) => t._id));
        }
      })
      .catch((error) => {
        setApplicationContext((currentContextState) => ({
          ...currentContextState,
          pageLoading: false,
        }));

        console.log('Error while fetching Sub-Topics', error);
      });
  };

  const handleClassesSelection = (event) => {
    const currentSelectedClass = event.currentTarget.value;

    setSelectedFilterClass(
      currentSelectedClass === selectedFilterClass ? '' : currentSelectedClass
    );
  };

  const handleSubjectsSelection = (event) => {
    const selectedSubject = event.currentTarget.value;
    const selectedSubjectsCopy = cloneDeep(selectedSubjects);
    const selectedSubjectIndex = selectedSubjectsCopy.indexOf(selectedSubject);
    if (selectedSubjectIndex !== -1) {
      selectedSubjectsCopy.splice(selectedSubjectIndex, 1);
    } else {
      selectedSubjectsCopy.push(selectedSubject);
    }

    setSelectedSubjects(selectedSubjectsCopy);
    props.setSelectedSubjects(selectedSubjectsCopy);
  };

  const handleSubtopicSelection = (event) => {
    const selectedSubtopicObj = JSON.parse(event.currentTarget.value);

    const selectedSubtopic = selectedSubtopicObj._id;
    const selectedSubtopicsCopy = cloneDeep(selectedSubtopics);
    const selectedSubtopicsArrCopy = cloneDeep(selectedSubtopicsArr);
    const selectedSubtopicIndex =
      selectedSubtopicsCopy.indexOf(selectedSubtopic);
    if (selectedSubtopicIndex !== -1) {
      selectedSubtopicsCopy.splice(selectedSubtopicIndex, 1);
      selectedSubtopicsArrCopy.splice(selectedSubtopicIndex, 1);
    } else {
      selectedSubtopicsCopy.push(selectedSubtopic);
      selectedSubtopicsArrCopy.push(selectedSubtopicObj);
    }

    setSelectedSubtopics(selectedSubtopicsCopy);
    setSelectedSubtopicsArr(selectedSubtopicsArrCopy);
    props.setSelectedSubtopics(selectedSubtopicsCopy);
  };

  const toggleShowInstructions = () => {
    setApplicationContext((currentContextState) => ({
      ...currentContextState,
      instructionDialog: !currentContextState.instructionDialog,
    }));
  };

  return (
    <aside className={`${styles['page-home-aside']}`}>
      <section className='aside-help'>
        <button
          type='button'
          className='aside-help-trigger'
          onClick={() => toggleShowInstructions()}
        >
          <i class='fa-solid fa-circle-info'></i>
        </button>
      </section>

      {classes && classes.length !== 0 && (
        <section className={`${styles['aside-section']}`}>
          <h6 className={`${styles['aside-section-title']}`}>
            Filter by Class
          </h6>

          <ul className={`${styles['aside-section-choices']}`}>
            {classes.map((classItem) => {
              return (
                <li key={classItem._id}>
                  <SingleCheck
                    id={classItem._id}
                    value={classItem._id}
                    name={'classSelector'}
                    label={classItem.className}
                    onChange={handleClassesSelection}
                    checkedValue={selectedFilterClass}
                  />
                </li>
              );
            })}
          </ul>
        </section>
      )}

      {subjects && subjects.length !== 0 && (
        <section className={`${styles['aside-section']}`}>
          <h6 className={`${styles['aside-section-title']}`}>
            Filter by Subject
          </h6>

          <ul className={`${styles['aside-section-choices']}`}>
            {subjects.map((subjectItem) => {
              return (
                <li key={subjectItem._id}>
                  <MultiCheck
                    id={subjectItem._id}
                    value={subjectItem._id}
                    label={subjectItem.subjectName}
                    onChange={handleSubjectsSelection}
                  />
                </li>
              );
            })}
          </ul>
        </section>
      )}

      {selectedFilterClass &&
        selectedSubjects &&
        selectedFilterClass !== '' &&
        selectedSubjects.length !== 0 &&
        topics &&
        topics.length !== 0 && (
          <section className={`${styles['aside-section']}`}>
            <>
              <h6 className={`${styles['aside-section-title']}`}>
                Filter by Topic/Sub-Topic
              </h6>

              <AccordionFilter
                topics={topics}
                handleSubtopicSelection={handleSubtopicSelection}
              />
            </>
          </section>
        )}
    </aside>
  );
};

export default FilterAside;
