export const contextInitialState = {
  pageLoading: false,
  sideBarLoading: false,
  instructionDialog: false,
  authenticationToken: null,
};

export const NavigationPaths = {
  HOME: '/',
  CATCHALL: '*',
  BROWSE: '/worksheets',
  ROADBLOCK: '/roadblock',
  DETAILS: '/worksheets/:slugIdentifier',
};

export const APIPaths = {
  LOGIN: `/${process.env.REACT_APP_API_PREFIX}/auth/`,
  WORKSHEETS: `/${process.env.REACT_APP_API_PREFIX}/worksheets/`,
  FILTERS: `/${process.env.REACT_APP_API_PREFIX}/worksheets/filters`,
  FILTERS_TOPICS: `/${process.env.REACT_APP_API_PREFIX}/worksheets/filters/topics`,
  FILTERS_SUBTOPICS: `/${process.env.REACT_APP_API_PREFIX}/worksheets/filters/subtopics`,
  WORKSHEET_SLUG: `/${process.env.REACT_APP_API_PREFIX}/worksheets/:slugIdentifier`,
  WORKSHEET_PRINT: `/${process.env.REACT_APP_API_PREFIX}/worksheets/print/:identifier`,
};
