import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NavigationPaths } from 'common/constants';

import ApplicationContext from 'hocs/ApplicationContext';

import SkipinLogo from 'assets/images/logos/skipin-logo-square.svg';
import NeeveLogo from 'assets/images/logos/neeve-logo-square.svg';

import styles from './header.module.scss';

const Header = () => {
  const navigate = useNavigate();
  const { applicationContext } = useContext(ApplicationContext);

  const handleLogout = () => {
    if (applicationContext.authenticationToken) {
      navigate(NavigationPaths.ROADBLOCK);
    }
  };

  return (
    <header
      className={`${styles['single-page-header']} ${styles['page-header']}`}
    >
      <div className={`${styles['page-header-left']}`}>
        <h3 className={`${styles['page-header-title']}`}>
          Worksheet Warehouse
        </h3>
      </div>

      <div className={`${styles['page-header-right']}`}>
        <ul className={`${styles['page-header-brands']}`}>
          <li>
            <Link to={NavigationPaths.BROWSE}>
              <img src={SkipinLogo} alt='Skipin' />
            </Link>
          </li>
          <li>
            <Link to={NavigationPaths.BROWSE}>
              <img src={NeeveLogo} alt='Neeve' />
            </Link>
          </li>
        </ul>

        {applicationContext?.authenticationToken &&
          applicationContext?.authenticationToken !== null && (
            <button
              type='button'
              title='Logout'
              onClick={() => handleLogout()}
              className={`${styles['page-header-logout']}`}
            >
              <i className='fa-solid fa-power-off'></i>
            </button>
          )}
      </div>
    </header>
  );
};

export default Header;
