import React, { useContext, useEffect, useState } from 'react';

import MultiCheck from 'components/MultiCheck';
import WorksheetService from 'services/Worksheet';
import ApplicationContext from 'hocs/ApplicationContext';

import styles from './accordion-filter-item.module.scss';

const AccordionFilterItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subtopics, setSubtopics] = useState([]);
  const { setApplicationContext } = useContext(ApplicationContext);

  useEffect(() => {
    if (isOpen && !subtopics.length) {
      getSubtopics();
    }
  }, [isOpen]); // eslint-disable-line

  const getSubtopics = () => {
    setApplicationContext((currentContextState) => ({
      ...currentContextState,
      pageLoading: true,
    }));

    WorksheetService.filtersSubtopics({
      topicId: props.id,
    })
      .then((response) => {
        setApplicationContext((currentContextState) => ({
          ...currentContextState,
          pageLoading: false,
        }));

        if (response && response.data) {
          setSubtopics(response.data);
        }
      })
      .catch((error) => {
        console.log('Error while fetching Sub-Topics', error);

        setApplicationContext((currentContextState) => ({
          ...currentContextState,
          pageLoading: false,
        }));
      });
  };

  return (
    <div className={`${styles['accordion-item']}`}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`${styles['accordion-header']}`}
      >
        <span className={`${styles['accordion-header-text']}`}>
          {props.topic}
        </span>

        <i
          className={`fa-solid ${isOpen ? 'fa-caret-down' : 'fa-caret-right'}`}
        ></i>
      </div>

      {isOpen && subtopics && subtopics.length !== 0 && (
        <div className={`${styles['accordion-body']}`}>
          <ul className={`${styles['aside-section-choices']}`}>
            {subtopics.map((subtopicItem) => {
              return (
                <li key={subtopicItem._id}>
                  <MultiCheck
                    id={subtopicItem._id}
                    label={subtopicItem.subtopicName}
                    value={JSON.stringify(subtopicItem)}
                    onChange={props.handleSubtopicSelection}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccordionFilterItem;
