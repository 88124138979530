import React, { useState } from 'react';

import styles from './master-search.module.scss';

const MasterSearch = (props) => {
  const [search, setSearch] = useState('');

  const searchHandler = () => {
    props.setSearch(search);
    setSearch('');
  };

  const typeSearchHandler = (event) => {
    if (event.key === 'Enter') {
      searchHandler();
    }
  };

  return (
    <section className={`${styles['master-search-header']}`}>
      <h1 className={`${styles['master-search-title']}`}>
        Search for Printable Worksheets
      </h1>

      <div className={`${styles['master-search-operations']}`}>
        <input
          type='text'
          value={search}
          id='masterSearch'
          name='masterSearch'
          onKeyDown={typeSearchHandler}
          className={`${styles['search-input']}`}
          onChange={(e) => setSearch(e.currentTarget.value)}
          placeholder='Search for a Worksheet by typing a Topic, Subject or Class'
        />

        <button
          type='button'
          onClick={searchHandler}
          className={`${styles['search-submit']}`}
        >
          Search <i className='fa-solid fa-arrow-right'></i>
        </button>
      </div>

      <div className={`${styles['master-search-entries']}`}>
        {props.search && (
          <div className={`${styles['search-pill']}`}>
            <span className={`${styles['search-pill-text']}`}>
              {props.search}
            </span>

            <button
              type='button'
              onClick={() => props.setSearch('')}
              title='Remove Search'
              className={`${styles['search-pill-action']}`}
            >
              X
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default MasterSearch;
