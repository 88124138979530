import axiosInstance from './Network';

import { APIPaths } from 'common/constants';

const AuthService = {
  login: (dataPayload) => {
    return axiosInstance.post(APIPaths.LOGIN, dataPayload);
  },
};

export default AuthService;
