import React from 'react';

import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from 'common/constants';

import PlaceholderPhoto from 'assets/images/backgrounds/placeholder.jpg';

import styles from './grid-element.module.scss';

const GridElement = (props) => {
  const navigate = useNavigate();

  const renderingAltText = `${props?.title}`
    .trim()
    .toLowerCase()
    .replace(/[ ]/g, '-')
    .replace(/[!@#$%^&*()<>?:"{}|]/g, '');

  const renderingTitle = `${props?.title
    .trim()
    .replace(/[!@#$%^&*()<>?:"{}|]/g, '')}`;

  return (
    <div className={`${styles['grid-element']}`}>
      <span
        className={`${styles['grid-element-subject']}`}
        style={{ backgroundColor: props.subject.hex }}
      >
        {props.subject.subjectName}
      </span>

      <div
        className={`${styles['grid-element-image']}`}
        style={{ backgroundColor: props.subject.hex }}
      >
        <img
          loading='lazy'
          alt={renderingAltText}
          src={props.thumbnail || PlaceholderPhoto}
          className={`${styles['grid-element-photo']}`}
        />
      </div>

      <div
        className={`${styles['grid-element-content']}`}
        onClick={() =>
          navigate(
            NavigationPaths.DETAILS.replace(':slugIdentifier', props.slug)
          )
        }
      >
        <h6 className={`${styles['grid-element-title']}`}>{renderingTitle}</h6>

        <div className={`${styles['grid-element-metadata']}`}>
          <p className={`${styles['metadata-classes']}`}>
            {props?.classes && props?.classes.length > 1
              ? `For Classes ${props.classes?.join(', ')}`
              : `For Class ${props.classes?.join(', ')}`}
          </p>
        </div>

        <div className={`${styles['grid-element-metadata']}`}>
          <p className={`${styles['metadata-topics']}`}>
            {`${props.topic} / ${props.subtopics?.join(', ')}`}
          </p>
        </div>

        <div className={`${styles['grid-element-instructions']}`}>
          <span className={`${styles['grid-element-helptext']}`}>
            Click to View Details or Print
          </span>
        </div>
      </div>
    </div>
  );
};

export default GridElement;
