import React, { useEffect, useState } from 'react';

import styles from './single-check.module.scss';

const SingleCheck = (props) => {
  const [checkedStatus, setCheckedStatus] = useState(false);

  useEffect(() => {
    if (props.checkedValue === props.value) {
      setCheckedStatus(true);
    } else {
      setCheckedStatus(false);
    }
  }, [props.checkedValue, props.value]);

  return (
    <label htmlFor={props.id} className={`${styles['singlecheck-label']}`}>
      <input
        type='radio'
        id={props.id}
        name={props.name}
        value={props.value}
        checked={checkedStatus}
        onChange={props.onChange}
        onClick={props.onChange}
        className={`${styles['singlecheck-label-input']}`}
      />

      <span className={`${styles['singlecheck-label-selector']}`}>
        <span className={`${styles['singlecheck-label-icon']}`} />
      </span>

      <span className={`${styles['singlecheck-label-text']}`}>
        {props.label}
      </span>
    </label>
  );
};

export default SingleCheck;
