import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { constructPrintableUrl } from 'common/helpers';

import Header from 'components/Header';
import PrintFrame from 'components/PrintFrame';
import WorksheetService from 'services/Worksheet';
import ApplicationContext from 'hocs/ApplicationContext';

import WorksheetPlaceholder from 'assets/images/backgrounds/worksheet-placeholder.jpg';
import SectionTwo from 'assets/images/sections/section-two.png';
import SectionThree from 'assets/images/sections/section-three.png';

import styles from './worksheet-detail.module.scss';

const WorksheetDetail = () => {
  const newRef = useRef(null);
  const params = useParams();
  const { setApplicationContext } = useContext(ApplicationContext);

  const [worksheetData, setWorksheetData] = useState(null);
  const [worksheetUrl, setWorksheetUrl] = useState(null);

  useEffect(() => {
    if (params.slugIdentifier) {
      getWorksheetDetails();
    }
  }, [params.slugIdentifier]); // eslint-disable-line

  const getWorksheetDetails = () => {
    setApplicationContext((currentContextState) => ({
      ...currentContextState,
      pageLoading: true,
    }));

    WorksheetService.worksheetDetails({
      slugIdentifier: params.slugIdentifier,
    })
      .then((responseData) => {
        setApplicationContext((currentContextState) => ({
          ...currentContextState,
          pageLoading: false,
        }));

        if (responseData && responseData.data) {
          setWorksheetData(responseData.data);
        }
      })
      .catch((error) => {
        setApplicationContext((currentContextState) => ({
          ...currentContextState,
          pageLoading: true,
        }));

        console.log('Error while fetching Worksheet information', error);
      });
  };

  const printWorksheet = () => {
    setApplicationContext((currentContextState) => ({
      ...currentContextState,
      pageLoading: true,
    }));

    WorksheetService.print({
      identifier: worksheetData?._id,
    })
      .then((responseData) => {
        setApplicationContext((currentContextState) => ({
          ...currentContextState,
          pageLoading: false,
        }));

        if (responseData && responseData.data) {
          const printableUrl = constructPrintableUrl(responseData.data);

          if (printableUrl !== '') {
            setWorksheetUrl(printableUrl);
          }
        }
      })
      .catch((error) => {
        setApplicationContext((currentContextState) => ({
          ...currentContextState,
          pageLoading: false,
        }));

        console.log('Error while fetching Worksheet Print information', error);

        setWorksheetUrl(null);
      });
  };

  const handlePrintComplete = () => {
    setWorksheetUrl(null);
  };

  return (
    <>
      <Header />

      <div className={`single-page ${styles['page-view']}`}>
        {worksheetData && (
          <main className='single-page-container'>
            <section className={`${styles['page-view-header']}`}>
              <h1 className={`${styles['page-view-title']}`}>
                {worksheetData?.worksheetTitle}
              </h1>
            </section>

            <section className={`${styles['page-view-content']}`}>
              <div
                className={`${styles['left-content']}`}
                style={{ backgroundColor: worksheetData?.subjectId.hex }}
              >
                <div className={`${styles['left-content-conceal']}`}>
                  <p>&copy; Wise Owl Learning</p>
                  <p>&copy; Wise Owl Learning</p>
                </div>

                <img
                  width='100%'
                  height='100%'
                  alt='Worksheet'
                  className={`${styles['left-content-photo']}`}
                  src={worksheetData?.screenshotUrl || WorksheetPlaceholder}
                />
              </div>

              <div className={`${styles['right-content']}`}>
                <div className={`${styles['content-datapoint']}`}>
                  <span className={`${styles['content-datapoint-label']}`}>
                    Class
                  </span>
                  <span className={`${styles['content-datapoint-value']}`}>
                    {worksheetData?.classes?.join(', ')}
                  </span>
                </div>

                <div className={`${styles['content-datapoint']}`}>
                  <span className={`${styles['content-datapoint-label']}`}>
                    Subject
                  </span>
                  <span className={`${styles['content-datapoint-value']}`}>
                    {worksheetData?.subjectId?.subjectName}
                  </span>
                </div>

                <div className={`${styles['content-datapoint']}`}>
                  <span className={`${styles['content-datapoint-label']}`}>
                    Topic & Sub-Topic
                  </span>
                  <span className={`${styles['content-datapoint-value']}`}>
                    {worksheetData?.subtopics &&
                    worksheetData?.subtopics.length > 1 ? (
                      <>
                        <span
                          className={`${styles['content-datapoint-subtitle']}`}
                        >{`${worksheetData?.topicId?.topicName}`}</span>

                        <ul className={`${styles['content-datapoint-list']}`}>
                          {worksheetData?.subtopics.map(
                            (currentSubTopic, currentSubTopicIdentifier) => (
                              <li key={currentSubTopicIdentifier}>
                                {currentSubTopic}
                              </li>
                            )
                          )}
                        </ul>
                      </>
                    ) : (
                      <>{`${
                        worksheetData?.topicId?.topicName
                      } / ${worksheetData?.subtopics?.join(', ')} `}</>
                    )}
                  </span>
                </div>

                <div className={`${styles['content-datapoint']}`}>
                  <span className={`${styles['content-datapoint-label']}`}>
                    Skill
                  </span>
                  <span className={`${styles['content-datapoint-value']}`}>
                    {worksheetData?.skill}
                  </span>
                </div>

                <div className={`${styles['content-datapoint']}`}>
                  <span className={`${styles['content-datapoint-label']}`}>
                    Difficulty
                  </span>
                  <span className={`${styles['content-datapoint-value']}`}>
                    {worksheetData?.difficulty}
                  </span>
                </div>

                <div className={`${styles['content-datapoint']}`}>
                  <span className={`${styles['content-datapoint-label']}`}>
                    Answer Key
                  </span>
                  <span className={`${styles['content-datapoint-value']}`}>
                    {worksheetData?.includesAnswerKey
                      ? 'Available'
                      : 'Not Available'}
                  </span>
                </div>

                <div className={`${styles['content-datapoint']}`}>
                  <span className={`${styles['content-datapoint-label']}`}>
                    Learning Outcome
                  </span>
                  <span className={`${styles['content-datapoint-value']}`}>
                    {worksheetData?.learningObjective}
                  </span>
                </div>

                {worksheetData?.keywords &&
                  worksheetData.keywords.length !== 0 && (
                    <div className={`${styles['content-datapoint']}`}>
                      {worksheetData.keywords.map((keyword, keywordIndex) => {
                        return (
                          <span
                            key={keywordIndex}
                            className={`${styles['content-datapoint-pill']}`}
                          >
                            <i class='fa-solid fa-tag'></i>
                            {keyword}
                          </span>
                        );
                      })}
                    </div>
                  )}

                <button
                  type='button'
                  onClick={printWorksheet}
                  className={`${styles['content-action']}`}
                >
                  <i class='fa-solid fa-print'></i> Print Worksheet
                </button>
              </div>
            </section>

            <section className={`${styles['page-view-static']}`}>
              <div className={`${styles['page-view-conceal']}`}></div>

              <img
                width='100%'
                height='100%'
                alt='Worksheet'
                src={SectionTwo}
              />
            </section>

            <section className={`${styles['page-view-static']}`}>
              <div className={`${styles['page-view-conceal']}`}></div>

              <img
                width='100%'
                height='100%'
                alt='Worksheet'
                src={SectionThree}
              />
            </section>

            {worksheetUrl && (
              <PrintFrame
                ref={newRef}
                src={worksheetUrl}
                closePrintFrame={handlePrintComplete}
              />
            )}
          </main>
        )}
      </div>
    </>
  );
};

export default WorksheetDetail;
