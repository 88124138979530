import React, { useEffect } from 'react';

import styles from './print-frame.module.scss';

const PrintFrame = React.forwardRef((props, forwardedRef) => {
  useEffect(() => {
    document.body.classList.add('lock-scroll');

    return () => {
      document.body.classList.remove('lock-scroll');
    };
  }, []);

  return (
    <div
      className={`${styles['print-frame']}`}
      onClick={() => props?.closePrintFrame()}
    >
      <div className={`${styles['print-frame-header']}`}>
        <h4 className={`${styles['print-frame-instructions']}`}>
          Click on PDF Print Icon
        </h4>

        <i className='fa-solid fa-arrow-down'></i>
      </div>

      <embed
        src={props?.src}
        ref={forwardedRef}
        className={`${styles['print-frame-embed']}`}
      />
    </div>
  );
});

export default PrintFrame;
